.cp_main
{
    display: flex;
    padding: 140px 240px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    background: #F1F1F1;
    align-self: stretch;
}
.cp_bar
{
display: flex;
width: 100%;
justify-content: space-around;
align-items: center;
background: #E6E6E6;
}
.cp_bar_box
{
display: flex;
padding: 9px;
justify-content: center;
align-items: center;
text-align: center;
}
.cp_bar_text
{
color: #333;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
}
.cp_company_header
{
display: inline-flex;
justify-content: center;
align-items: center;
color: #1A1A1A;
font-size: 44px;
font-style: normal;
font-weight: 500;
line-height: normal; /* 150% */
}

@media (max-width: 768px) {
    .cp_main
{
    display: flex;
    padding: 35px;
}
.cp_bar_box
{
display: flex;
padding: 9px;
justify-content: center;
align-items: center;
}
}