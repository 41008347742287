.kawaii_way_main
{
display: flex;
min-height: 400px;
padding: 150px 240px;
justify-content: center;
align-items: flex-start;
gap: 24px;
align-self: stretch;
position: relative;
background: #F8F9FA;
}
.kawaii_sub_way
{
display: flex;
width: 100%;
padding: 24px 0px;
justify-content: flex-end;
align-items: center;
gap: 160px;
position: relative;
}
.svgpattern
{
position: absolute;
left: -240px;
bottom: -133px;
    z-index: 0;
}
.kawaii_way_content
{
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 48px;
flex: 1 0 0;
}
.kawaii_way_content_title
{
color: #5F6368;
leading-trim: both;
text-edge: cap;

/* KG_H1 */
/* font-family: "Segoe UI"; */
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 109.091% */
}
.kawaii_way_content_sub
{
    color: #202124;
leading-trim: both;
text-edge: cap;

/* KG_sub_heading */
/* font-family: "Segoe UI"; */
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.kawaii_way_icon_box
{
    display: flex;
align-items: flex-start;
gap: 48px;
align-self: stretch;
}
.kawaii_way_icon_col
{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
flex: 1 0 0;
}
.kawaii_way_icon_inside
{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
}
.kawaii_way_icon_frame
{
width: 104px;
height: 105px;
}
.kawaii_way_circle
{
width: 104px;
height: 104px;
padding: 3px 0 0 0;
flex-shrink: 0;
border-radius: var(--Spacing---1, 100px);
border: 2px dashed #5F6368;
position:relative;
}
.kawaii_way_circle_bg
{
display: inline-flex;
padding: 16px;
align-items: center;
border-radius: var(--Spacing---1, 100px);
border: 1px solid #F1F1F1;
background: #E9E9E9;
position:absolute;
}
.kawaii_way_icon_title
{
color: #5F6368;
/* font-family: "Segoe UI"; */
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 110%; /* 26.4px */
}
.kawaii_way_icon_description
{
color: #202124;
font-family: "Segoe UI";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 28.8px */
align-self: stretch;
}

.left_img_frame_kg {
    display: flex;
    width: 500px;
    height: 763px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    border-radius: 6px;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;  /* Added to contain the background image */
}

.left_img_bg {
    position: absolute;
    left: -440px;
    width: 1149.832px;
    height: 763px;
    background-image: url('../../Assets/leftimgkgway.png');
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    z-index: 0;
}

.corner_kg_icon
{
    display: flex;
padding: 88px 19px 0px 0px;
flex-direction: column;
justify-content: flex-end;
align-items: center;
position: absolute;
left: -260px;
bottom: -159px;
z-index:1;
}


/* mobile responsive  */
@media (max-width: 768px) {
.kawaii_way_main
{
padding: 16px 16px 32px 16px;
}
.svgpattern, .corner_kg_icon{
    display:none;
}
.kawaii_sub_way {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 48px;
    flex: 1 0 0;
    }
.left_img_frame_kg {
height: 600px;
width:100%;
align-items: center;
align-self: stretch;
}

.left_img_bg {
    width: 904.193px;
height: 600px;
left: -363px;
top: 0.048px;
}
.kawaii_way_content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    align-self: stretch;
}
.kawaii_way_content_title
{
text-align: center;
font-size: 30px;
font-weight: 700;
}
.kawaii_way_content_sub
{
text-align: center;
}
.kawaii_way_icon_box
{
    display: flex;
    flex-direction: column;
align-items: center;

}
.kawaii_way_icon_col
{
  
align-items: center;
}
.kawaii_way_icon_inside
{
    display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
align-self: stretch;
}
.kawaii_way_icon_title
{
text-align: center;
}
.kawaii_way_icon_description
{
text-align: center;
}
}