.projectheroPrevNext
{
    display: inline-flex;
    align-items: center;
    gap: 12px;
    right: 32px;
    bottom: 30px;
}
.projectsliderArrowDirection
{
display: flex;
width: 64px;
height: 64px;
padding: 4px;
justify-content: center;
align-items: center;
border-radius: 64px;
border: 1px solid rgba(0, 0, 0, 0.30);
cursor: pointer;
}
.projectherosliderArrow
{
display: flex;
width: 56px;
height: 56px;
padding: 4px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 64px;
background: rgba(0, 0, 0, 0.30);
backdrop-filter: blur(15px);
}

@media (max-width: 768px) {
    .projectheroPrevNext
{
display:none;
}
  }
