.ourventuresMain
{
    display: flex;
    min-height: 400px;
    padding: 140px 240px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background: #F8F9FA;
position: relative;
}
.ourventureTitle
{
    color: #5F6368;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 109.091% */
}
.ourventureDescription
{
    color: #202124;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.OurventureSliderBox {
    display: flex;
    width: 100%;
    padding: 48px 0px;
    align-items: flex-start;
    gap: 24px;
    overflow: hidden;
  }
  
  .embla__container {
    display: flex;
    gap: 24px;
    padding: 0 24px;
  }

  @media (max-width: 768px) {
    .ourventuresMain
{
padding: 32px 16px;
gap: 32px;
}
  }

