.modal-overlay {
    position: absolute; /* Ensures the modal stays fixed to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
    display: flex;
    justify-content: center; /* Centers the modal horizontally */
    align-items: center; /* Centers the modal vertically */
    z-index: 1000; /* Ensures the modal is above other elements */
}

.modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative; /* Ensures elements inside are positioned relative to this container */
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.modal-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.modal-button {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    color: white;
    background-color: #BE1E2D;
    text-align: center;
    cursor: pointer;
}

.modal-button:hover {
    background-color: #1A1A1A;
}
