.button_normal_cta 
{
display: flex;
padding: 12px 24px !important;
justify-content: center;
align-items: center;
gap: 2px;
border-radius: 3px;
background: #BE1E2D!important;
transition: background-color 0.3s ease;
}
.button_normal_cta:hover {
    background: #1A1A1A !important;
}
.btn_normal_sub
{
display: flex;
align-items: flex-start;
gap: 6px;
}
.btn_normal_text
{
color: #FFF;
leading-trim: both;
text-edge: cap;
font-family: "Segoe UI";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 137.5% */
}