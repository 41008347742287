.OurProjectCardBox {
    display: flex;
    width: 45%;
    padding: 25px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 8px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}
.OurProjectCardBox::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute; /* Position it absolutely within the parent */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    bottom: 0; /* Align to the bottom */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 15%, #000 100%);
    border-radius: 8px;
    z-index: 1; /* Ensure it sits above the background but below content */
    transition: background 0.3s ease;
}
.OurProjectCardBox:hover::before {
    background: rgba(0, 0, 0, 0.85);
  }

.OurProjectCardBox > * {
    position: relative; /* Ensure child elements are above the overlay */
    z-index: 2; /* Bring content above the overlay */
}
.OurProjectContentBox
{
display: flex;
padding-top: 210px;
flex-direction: column;
align-items: flex-start;
gap: 15px;
align-self: stretch;
}
.OurProjectContentTitle
{
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    align-self: stretch;
}

.OurProjectCardBox:hover .OurProjectContentTitle {
    text-decoration: underline; /* Adds an underline */
    text-decoration-thickness: 2px; /* Adjusts the thickness of the underline */
    text-decoration-color: #fff; /* Ensures the underline color matches the text color */
    text-underline-offset: 2px; /* Adds spacing between the text and the underline */
  }

.OurProjectContentSub
{
    overflow: hidden;
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
}
.OurProjectContentDes
{
    overflow: hidden;
    color: var(--White, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    height: 60px;
    align-self: stretch;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
}

.OurProjectContentReadmore {
    display: flex;
    align-items: flex-start;
    align-items: center;
    gap: 5px;
    flex: 1;
    padding: 0;
    transition: all 300ms ease-in-out;
    position: relative;
    width:100%;
}

/* For group hover effect */
.OurProjectCardBox:hover .OurProjectContentReadmore {
    padding-left: 28rem;
}


.OurProjectReadMore
{
    color: #EDC8A3;
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 25px; /* 208.333% */
text-transform: uppercase;
}


@media (max-width: 768px) {
    .OurProjectCardBox {
        width: 60%;
        overflow: hidden;
        max-width: 60%;
    }

    .OurProjectContentReadmore {
        max-width: 100%;
        overflow: hidden;
    }

    .OurProjectCardBox:hover .OurProjectContentReadmore {
        padding-left: 0 !important; /* Reset padding */
        transform: translateX(80%);
    }

    .OurProjectContentBox {
        width: 100%;
        overflow: hidden;
    }
  }