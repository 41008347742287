.makeDiff
{
    display: flex;
min-height: 400px;
padding: 140px 240px;
flex-direction: column;
align-items: center;
gap: 24px;
background: #F8F9FA;
align-self: stretch;
position:relative
}
.textBoxDiff
{
display: flex;
align-items: flex-end;
justify-content: center;
gap: 24px;
align-self: stretch;
}
.titleDiff
{
    color: #5F6368;
text-align: center;
leading-trim: both;
text-edge: cap;

/* KG_H1 */
/* font-family: "Segoe UI"; */
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 109.091% */
}
.diffCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* max-width: 464px; */
    gap: 24px;
    flex: 1 0 0;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 24px 44px 0px rgba(20, 20, 43, 0.04);
    align-self: stretch;
    overflow: hidden; /* Ensure child elements don't break border radius */
    position: relative;
}

.diffCardImg {
    display: flex;
    height: 200px;
    padding: 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
    position: relative;
   
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px 6px 0 0; /* Only top corners rounded */
}

/* Overlay using pseudo-element */
.diffCardImg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* 40% black overlay */
    pointer-events: none;
    z-index: 1;
    border-radius: inherit; /* Inherit border-radius from parent */
}
.diffCardImg > * {
    position: relative;
    z-index: 2;
}
.DiffCardTitle
{
    color: #FFF;
leading-trim: both;
text-edge: cap;
/* font-family: "Segoe UI"; */
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 200% */
}

.diffCardCornerBg
{
    position: absolute;
left: 0;
top: 0;
}
.diffCardCornerDownBg
{
position: absolute;
right: 0px;
bottom: -9px;
}
.diffCardContentBox
{
    display: flex;
height: 147px;
padding: 0px 24px 24px 24px;
flex-direction: column;
align-items: flex-start;
gap: 48px;
align-self: stretch;
}
.diffCardContentDescription
{
    overflow: hidden;
color: #5F6368;
/* KG_sub_heading */
/* font-family: "Segoe UI"; */
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
flex: 1 0 0;
align-self: stretch;
}
.diffCardMainBottomCorner
{
display: flex;
padding: 88px 19px 0px 0px;
flex-direction: column;
justify-content: flex-end;
align-items: center;
position: absolute;
z-index:0;
right: 0px;
bottom: 0px;
}

@media (max-width: 768px) {
    .makeDiff
{
padding: 32px 16px;
align-items: flex-start;
}

.titleDiff
{
flex: 1 0 0;
font-size: 30px;
font-weight: 700;
line-height: normal;
}
}