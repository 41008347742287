.cta_main
{
display: flex;
/* min-height: 400px; */
padding: 140px 240px;
flex-direction: column;
align-items: center;
gap: 48px;
align-self: stretch;
}
.cta_sub
{
display: flex;
height: 324px;
align-items: flex-start;
align-self: stretch;
border-radius: 15px;
background: #F1F1F1;
}

.left_cta {
    display:block;
    width: 480px;
    align-self: stretch;
    position: relative;
    border-top-left-radius: 15px;     /* Only left side top corner */
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }
  
  .main_bg {
    width: 439px;
    height: 324px;
    position: relative;
    fill: var(--Kawaii-Red, #BE1E2D);
  }
  
  .kg_logo_bg {
    width: 150px;
    height: 112.5px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .left_frame {
    position: absolute;
    width: 222px;
    height: 275px;
    top: 49px;
    left: 258px;
    background-color: #D9D9D9;
    clip-path: polygon(
    222px 0,    /* top-right */
    222px 275px,/* bottom-right */
    0 275px,    /* bottom-left */
    72px 0     /* top-left with 150px offset */
  );
    overflow: hidden; /* Add this to crop the overflowing image */
  }
  
  .cta_pic_bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    filter: brightness(0.8);
    /* Keeping your specific offset */
  }
  .left_title {
    position: absolute;
    color: #fff;
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 100% */
    top: 50%; /* Center vertically */
    left: 152px; /* Distance from left */
    transform: translateY(-50%); /* Perfect vertical centering */
    /* max-width: 200px; Control text width */
    z-index: 1; /* Ensure text appears above other elements */
  }

.right_cta
{
    display: flex;
    padding: 62px 161px 62px 149px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    background: #F1F1F1;
    border-top-right-radius: 15px;     /* Only left side top corner */
  border-bottom-right-radius: 15px !important;
}
.right_frame{
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 30px;
}
.right_title
{
color: #212121;
font-family: "Inter";
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: 40px; /* 100% */
}
.right_description
{
overflow: hidden;
color: #424242;
text-overflow: ellipsis;
font-family: "Segoe UI";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}
.button_cta 
{
display: flex;
padding: 12px 24px !important;
justify-content: center;
align-items: center;
gap: 2px;
border-radius: 3px;
border-bottom: 4px solid #000;
background: #BE1E2D!important;
transition: background-color 0.3s ease;
}
.button_cta:hover {
    background: #1A1A1A !important;
}
.btn_sub
{
display: flex;
align-items: flex-start;
}
.btn_text
{
color: #FFF;
leading-trim: both;
text-edge: cap;
font-family: "Segoe UI";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 137.5% */
}

@media (max-width: 1280px){
    .cta_main
{
    display: flex;
    min-height: 650px;
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background: #FFF;
}
.cta_sub
{
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 15px 15px 0 15px;
background: #F1F1F1;
}
.left_cta, .right_cta {
    width: 100%; /* Ensure both take full width */
    align-self: stretch;
  }
.left_cta {
    overflow: visible;
  }
  
  .right_cta
{
    display: flex;
    padding: 22px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: #F1F1F1;
    border-top-right-radius: 0px;     /* Only left side top corner */
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
  .right_frame{
    display: flex;
padding: 0px 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
    }
    .left_title {
        left: 72px; /* Distance from left */
      }
}