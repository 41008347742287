.KawaiiConcernsMain {
    display: flex;
    padding: 150px 240px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background: #FFF;
    align-self: stretch;
}

.KCHeadBox {
    display: flex;
    padding-bottom: 48px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.KCHeadTitle {
    color: #5F6368;
    text-align: center;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    /* 109.091% */
}

.KDHeadContent {
    color: var(--Blue-Gray-900, #0F172A);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 28.8px */
}

.KCCardBox {
    display: flex;
    min-height: 530px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.KCCardBox:hover {
    transform: scale(1.02);
    /* Slight zoom on hover */
}

.KCRect {
    width: 581.39px;
    height: 220px;
    transform: rotate(-15deg);
    position: absolute;
    left: -92.524px;
    bottom: 39.504px;
    border-radius: 70px;
}

.KCCardContentBox {
    display: flex;
    padding: 5px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.KCCardContentHeader {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    align-self: stretch;
}

.KCCardContentType {
    color: #5F6368;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 187.5% */
    align-self: stretch;
}

.KCCardDescriptionBox {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.KCCardDescription {
    color: #7D7D7D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    flex: 1 0 0;
}

.KCExploreDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
}

.KCExploreBtn {
    display: flex;
    width: 106.69px;
    padding: 1px 0px 5.8px 0px;
    justify-content: center;
    align-items: flex-end;
    gap: 5.408px;
    border-bottom: 1px solid #BE1E2D;
}

.KCExploreContent {
    display: flex;
    width: 85.282px;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #BE1E2D;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.8px;
    /* 170% */
    text-transform: capitalize;
}

.KCImageBox {
    display: flex;
    min-height: 220px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 0.5px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    position: relative;
    overflow: hidden;
    align-self: stretch;
}

.KCCardBg {
    position: absolute;
    z-index: 1;
    top: 0;
    object-fit: cover;
}

.KCimage-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    pointer-events: none;
}

.KCLogoContainer {
    width: 100%;
    height: 80px;
    position: absolute;
    z-index: 2;
    background: #FFF;
}

.KCCardLogo {
    padding: 8px 24px;
    /* Reduced top/bottom padding to 8px, kept left/right at 24px */
    border-radius: 2px;
    width: calc(100% - 48px);
    /* Adjusting for left/right padding */
    height: calc(100% - 16px);
    /* Adjusting for top/bottom padding */
    object-fit: contain;
    /* Changed to contain to show full logo */
    display: block;
}

/* Concern Details  */
.concern_detail_main_top {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 700px;
    padding-left: 240px;
    padding-right: 240px;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
}
.concernLeftWhite {
    display: flex;
    width: 400px;
    height: 300px;
    position: absolute;
    top: 0;
    left: -28px;
    z-index: 2;
}

.concernRightWhite {
    display: flex;
    width: 400px;
    height: 300px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.concertTitleText {
    font-family: Inter;
    font-weight: 700;
    font-size: 70px;
    line-height: 60px;
    letter-spacing: 0%;
    text-align: center;
    color: #434343;
    border-radius: 15px;
    padding-top: 115px;
    /* fix incorrect casing */
    padding-bottom: 48px;
    /* fix incorrect casing */
    position: relative;
    /* 👈 important */
    z-index: 5;
    width: 100%;
}

.concernBoxTitle {
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    text-transform: uppercase;
}

.concernBoxDes {
    font-family: Inter;
    font-weight: 400;
    font-style: italic;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0%;
    vertical-align: middle;

}

.concernBoxDetailText {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0%;

}

@media (max-width: 768px) {
    .KawaiiConcernsMain {
        padding: 36px 16px;
    }

    .KCHeadBox {
        padding-bottom: 24px;
    }

    .KCHeadTitle {
        font-size: 26px;
        line-height: normal;
    }

    .KDHeadContent {
        font-size: 15.5px;
        line-height: normal;
    }

    /* Details concern  */
    .concern_detail_main_top {
        min-height: 700px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .concernLeftWhite {
        width: 200px;
        height: 150px;
    }

    .concernRightWhite {
        width: 200px;
        height: 150px;
    }

    .concertTitleText {
        font-size: 30px;
        line-height: 100%;
        letter-spacing: 0%;
        border-radius: 15px;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .concernBoxTitle {
        font-family: Inter;
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        text-transform: uppercase;
    }

    .concernBoxDes {
        font-family: Inter;
        font-weight: 400;
        font-style: italic;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0%;
        vertical-align: middle;

    }

    .concernBoxDetailText {
        font-family: Inter;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0%;

    }
}