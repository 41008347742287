.main_contact_form
{
display: flex;
padding: 60px 240px var(--Spacing---1, 100px) 240px;
flex-direction: column;
justify-content: center;
align-items: center;
align-self: stretch;
background: #FFF;
}
.contactForm
{
color: #5F6368;
leading-trim: both;
text-edge: cap;
/* font-family: "Segoe UI"; */
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 250% */
}

@media (max-width: 768px) {
.main_contact_form
{
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: #FFF;
}
.contactForm
{
    color: #5F6368;
    leading-trim: both;
    text-edge: cap;
    /* font-family: "Segoe UI"; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: stretch;
}
}