.aboutFirstTitle
{
color: #5F6368;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 109.091% */
align-self: stretch;
}
.aboutFirstDes
{
    color: #202124;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
align-self: stretch;
}

@media (max-width: 768px){
    .aboutFirstTitle
{
    color: #5F6368;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
}